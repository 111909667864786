import IndexedDb from '@/utils/PartsDB';
import axios from 'axios';
import { dataServiceQuery } from '../api_configs';

export default {
    namespaced: true,
    state: {
        grouppedParts: {},
        groups: {}

     },
    actions: {
      /**
       * Find part by name
       * @param {Object} state Auto passed by vuex
       * @param {sting} query
       * @returns Array of found parts
       */
        findPart(state, query){
            const result = []
            for (let i = 0; i < Object.values(state.state.grouppedParts).length; i++) {
                const group = Object.values(state.state.grouppedParts)[i];
                for (let n = 0; n < group.length; n++) {
                    const part = group[n];
                    const queryWords = query.split(/(\s+)/)
                    let notMatch = false
                    for (let i = 0; i < queryWords.length; i++) {
                      const queryWord = queryWords[i];
                      if((part.title.toUpperCase().indexOf(queryWord.toUpperCase()) < 0) &&
                          ((part.title2||'').toUpperCase().indexOf(queryWord.toUpperCase()) < 0) &&
                          ((part.title3||'').toUpperCase().indexOf(queryWord.toUpperCase()) < 0)){
                        notMatch = true
                      }
                    }
                    if (!notMatch || part.sku.toUpperCase().indexOf(query.toUpperCase())>=0){
                        result.push(part)
                    }
                    //if (result.length>50) {return result}
                }
            }
            return result
        },
        /**
         * Get part groups
         * @param {Object} state Auto passed by vuex
         * @returns Array of part groups
         */
        async getGroups(state){
            const indexedDb = new IndexedDb()
            const groups = await indexedDb.getAll("groups")
            state.state.groups = groups
            return groups
        },
        /**
         * Get object contains groups keyed with group id-s
         * @param {Object} state Auto passed by vuex
         * @returns Object with groups keyed by group id-s
         */
        async getKeyedGroups(state){
            const indexedDb = new IndexedDb()
            const groups = await indexedDb.getAll("groups")
            state.state.groups = groups
            const keyedGroups = {}
            Object.values(groups).forEach((group)=> keyedGroups[group.id] = group)
            return keyedGroups
        },
        /**
         * Loads and returns grouped part list
         * @param {Object} state Auto passed by vuex
         * @returns Grouped part list
         */
        async getAllParts(state){
            const indexedDb = new IndexedDb()
            const groups = await indexedDb.getAll("groups")
            const parts = await indexedDb.getAll("parts")
            state.state.groups = groups
            await parts.forEach(part => {
                if(!state.state.grouppedParts[part.groupName])
                    state.state.grouppedParts[part.groupName] = []
                if(!(state.state.grouppedParts[part.groupName]|| []).some((tmpPart)=> tmpPart.id === part.id))
                    state.state.grouppedParts[part.groupName].push(part)
            });
            console.log(state.state.grouppedParts)
            return state.state.grouppedParts

        },
        /**
         * Download all parts and all groups and store them in IndexedDB
         * @param {Object} state Auto passed by vuex
         */
        async downloadAndSaveParts(state){
            if(!state.rootGetters['user/getUser'].email){
                return false
            }
            let downloadData
            try {
                downloadData = (await axios(dataServiceQuery("part", "getPartsAndGroups",{}))).data.data
              } catch (error) {
                console.log('Parts list download was unsuccesful')
                console.log(error.message)
              }
            if(!downloadData){
                console.log('Parts list download was unsuccesful')
                return
            }
            const indexedDb = new IndexedDb()
            downloadData.parts.forEach(part => {
              const foundGroup = downloadData.partTypes.find(p => p.id  === part['part_type_id'])
              if(foundGroup){
                part.groupName = `${foundGroup.erp_part_type_code? (foundGroup.erp_part_type_code||'')+' - ': ''}${foundGroup.title}`
              } else {
                part.groupName = "Nem csoportosított tételek"
                part.part_type_id = -1
              }
            });
            indexedDb.storeItems("parts",downloadData.parts)
            indexedDb.storeItems("groups",[...downloadData.partTypes, {id: -1, title: "Nem csoportosított tételek", prefilter_parts_logic: null}])
        }
      },
    getters: {

     }
  }
